import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import ResearchAndDevelopmentDocuments from "../../components/research-and-development/ResearchAndDevelopmentDocuments";

export default function ResearchAndDevelopmentLiquidBiochipPage({data}) {
    return (
        <article>
            <Helmet>
                <title>Жидкий биочип</title>
            </Helmet>
            <h2>Жидкий биочип</h2>
            <p>Стадия: Подготовка регистрационных документов</p>
            <ResearchAndDevelopmentDocuments>
                <ResearchAndDevelopmentDocuments.Document
                    title="патент"
                    url={data["patentFile"]["publicURL"]} />
            </ResearchAndDevelopmentDocuments>
            <p>Мультиплексная смесь моноклональных антител для флуоресцентной иммуноцитохимии опухолей с различными флуоресцентными метками.</p>
            <h3>Принцип метода</h3>
            <p>Реакция прямой иммунофлуоресценции.</p>
            <figure>
                <StaticImage
                    src="img/liquid-biochip-1.jpg"
                    alt="Наглядное изображение реакции прямой иммунофлуоресценции."
                    layout="constrained"
                    width={394} />
                <figcaption>Наглядное изображение реакции прямой иммунофлуоресценции.</figcaption>
            </figure>
            <h3>Преимущества</h3>
            <ul>
                <li>Время постановки реакции — 25 минут;</li>
                <li>Принцип иммуноцитохимии в пробирке.</li>
            </ul>
            <h3>Особенности</h3>
            <ul>
                <li>Необходимость дополнительного оборудования;</li>
                <li>Малый срок хранения препаратов.</li>
            </ul>
        </article>
    );
}

export const pageQuery = graphql`
    query {
        patentFile: file(relativePath: {eq: "patent-2753236.pdf"}) {
            publicURL
        }
    }  
`;
