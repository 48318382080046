import React from "react";
import PropTypes from "prop-types";

import * as classes from "./ResearchAndDevelopmentDocuments.module.scss";

export default function ResearchAndDevelopmentDocuments({children}) {
    return (
        <p className={classes.container}>
            Документы:{" "}
            <span className={classes.documentsContainer}>{children}</span>
        </p>
    );
}

function Document({title, url}) {
    return (
        <>
            <a
                className={classes.document}
                href={url}
                target="_blank"
                rel="noreferrer">{title}</a>
            {" "}
        </>
    );
}

Document.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

ResearchAndDevelopmentDocuments.Document = Document;
